.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(93, 102, 124);
  color: white;
}

/*
.cards-container {
  display: flex;
}

.card {
  color: white;
}
*/
